import styled from "@emotion/styled";
import CallIcon from "@mui/icons-material/Call";
import Button from "@mui/material/Button";
import { teal } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";

import Section from "../Section";

const theme = createTheme({
  palette: {
    primary: {
      main: teal["A700"],
    },
  },
});

const TextContainer = styled.div`
  text-align: center;
`;

const BottomContainer = styled.div`
  margin-bottom: 56px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const Footer = () => {
  return (
    <>
      <Section backgroundColor={teal[700]} padding="4" containerSize="md">
        <Grid container spacing={2}>
          <Grid xs={false} sm={3} />
          <Grid xs={12} sm={6}>
            <TextContainer>
              <Typography variant="body1">
                We really want to hear from you.
                <br />
                Seriously, we want to hear what you are thinking.
                <br />
                For real, Not kidding!
              </Typography>
            </TextContainer>
          </Grid>
          <Grid
            xs={12}
            sm={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ThemeProvider theme={theme}>
              <Button
                component={Link}
                to="/contact"
                variant="contained"
                startIcon={<CallIcon />}
              >
                Call us!
              </Button>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Section>
      <Section backgroundColor={teal[800]} padding="1">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ color: "white", textAlign: "center" }}
        >
          <Grid xs={12} sm={7}>
            <Typography variant="caption">
              Copyright {new Date().getFullYear()} © GreyFormStudio.com. All
              rights reserved.
            </Typography>
          </Grid>
          <Grid xs={12} sm={3}>
            <Typography variant="caption">Made With 💛 in India.</Typography>
          </Grid>
        </Grid>
      </Section>
      <BottomContainer> </BottomContainer>
    </>
  );
};

export default Footer;
