import styled from "@emotion/styled";
import { blueGrey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import one from "../../../assets/one.png";
import Section from "../../../components/Section";

const ImageContainer = styled.img`
  width: 60%;
  height: auto;

  @media (min-width: 600px) {
    width: 80%;
  }
`;
const TextContainer = styled.div`
  text-align: center;
  color: black;
  @media (min-width: 600px) {
    text-align: left;
  }
`;

const SecondSection = () => {
  return (
    <Section backgroundColor={blueGrey["100"]} padding="5">
      <Grid container spacing={2}>
        <Grid
          xs={12}
          sm={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ImageContainer
            loading="lazy"
            alt="Abstract illustration of buildings"
            src={one}
          />
        </Grid>
        <Grid xs={12} sm={8}>
          <TextContainer>
            <Typography variant="h2">WE MAKE PRETTY THINGS</Typography>
            <Typography variant="h6" component="p" gutterBottom>
              We always give our 100% ... unless we're donating{" "}
              <span style={{ color: "red" }}>blood.</span>
            </Typography>
          </TextContainer>
        </Grid>
      </Grid>
    </Section>
  );
};

export default SecondSection;
