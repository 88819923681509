import { blueGrey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import logo from "../../../assets/logo.png";
import ResponsiveImage from "../../../components/ResponsiveImage";
import Section from "../../../components/Section";

const OneSection = () => {
  return (
    <Section backgroundColor={blueGrey["800"]} padding="5">
      <Grid container spacing={2}>
        <Grid xs={12} sm={7}>
          <Typography variant="h4" component="h1" sx={{ color: "white" }}>
            Everything is designed. Few things are designed well.
          </Typography>
          <br />
          <Typography
            variant="h6"
            component="h2"
            sx={{ color: "#cfff48" }}
            gutterBottom
          >
            "Grey Form Studio" formerly known as FYI Architects
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ResponsiveImage alt="GreyForm studio logo" src={logo} width="60%" />
        </Grid>
      </Grid>
    </Section>
  );
};

export default OneSection;
