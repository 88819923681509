import styled from "@emotion/styled";
import type { Breakpoint } from "@mui/material";
import Container from "@mui/material/Container";

const Box = styled("div")<{ color: string; padding: string }>`
  background-color: ${(props) => props.color};
  padding: ${(props) => props.padding}vh 0;
`;

type SectionPropTypes = {
  backgroundColor: string;
  padding: string;
  containerSize?: Breakpoint;
  children: React.ReactNode;
};

const Section = ({
  backgroundColor,
  padding,
  containerSize = "md",
  children,
}: SectionPropTypes) => {
  return (
    // @ts-ignore
    <Box color={backgroundColor} padding={padding}>
      <Container maxWidth={containerSize}>{children}</Container>
    </Box>
  );
};

export default Section;
