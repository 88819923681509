import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import CssBaseline from "@mui/material/CssBaseline";
import Skeleton from "@mui/material/Skeleton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import BottomNavigationMobile from "./components/bottom-navigation/BottomNavigationMobile";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./pages/Home/Home";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

// const Home = lazy(
//   () =>
//     import(
//       /* webpackPrefetch: true, webpackChunkName: "home-page" */ "./pages/Home/Home"
//     )
// );
const About = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "about-page" */ "./pages/About/About"
    )
);
const Contact = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "contact-page" */ "./pages/Contact/Contact"
    )
);
const Projects = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "portfolio-page" */ "./pages/Projects/Projects"
    )
);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header />
        <Suspense
          fallback={<Skeleton variant="rounded" width="100vw" height="100vh" />}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<div />} />
          </Routes>
        </Suspense>
        <Footer />
        <BottomNavigationMobile />
      </ThemeProvider>
    </div>
  );
}

export default App;
