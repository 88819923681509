import styled from "@emotion/styled";
import { pink } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import { servicesArray } from "../../../components/data/services";
import Section from "../../../components/Section";

const ImageContainer = styled.img`
  width: 15%;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;
const BoxContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-bottom: 1rem;
`;

const PaperContainer = styled(Paper)`
  padding: 1rem;
  background-color: ${pink["700"]};
  @media (min-width: 600px) {
    min-height: 15rem;
  }
  color: white;
`;

const FifthSection = () => {
  return (
    <Section backgroundColor={pink["600"]} padding="5" containerSize="lg">
      <TextContainer>
        <Typography variant="h4" component="h2" sx={{ color: "white" }}>
          What we do
        </Typography>
        <Typography variant="h6" component="p" sx={{ color: "white" }}>
          Our services for your satisfaction
        </Typography>
      </TextContainer>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {servicesArray.map((item, index) => (
          <Grid xs={4} sm={4} md={4} key={index}>
            <PaperContainer elevation={3}>
              <BoxContainer>
                <ImageContainer loading="lazy" src={item.path} />
                <Typography variant="h6" component="h3" sx={{ color: "white" }}>
                  {item.title}
                </Typography>
              </BoxContainer>
              <Typography variant="body1" align="left">
                {item.desc}
              </Typography>
            </PaperContainer>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default FifthSection;
