import styled from "@emotion/styled";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { blueGrey } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

import HeaderLogo from "../../assets/header2-copy.png";

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey["A700"],
    },
  },
});

const ImageContainer = styled.img`
  width: 250px;
  height: auto;

  @media (max-width: 600px) {
    width: 100%;
    max-width: 300px;
    height: unset;
  }
`;
const LinkContainer = styled(Link)`
  display: flex;
`;

const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar color="primary" position="static">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <LinkContainer aria-label="Homepage for GreyForm studio" to="/">
                <ImageContainer alt="GreyForm studio text" src={HeaderLogo} />
              </LinkContainer>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Button component={Link} to="/" color="inherit">
                Home
              </Button>
              <Button component={Link} to="/projects" color="inherit">
                Projects
              </Button>
              <Button component={Link} to="/about" color="inherit">
                About
              </Button>
              <Button component={Link} to="/contact" color="inherit">
                Contact
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
};

export default Header;
