import styled from "@emotion/styled";

const ImageContainer = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
const ResponsiveImage = ({
  alt,
  src,
  width = "100%",
  height = "auto",
}: {
  alt: string;
  src: string;
  width?: string;
  height?: string;
}) => {
  return (
    <ImageContainer
      loading="lazy"
      alt={alt}
      src={src}
      width={width}
      height={height}
    />
  );
};

export default ResponsiveImage;
