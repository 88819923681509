import styled from "@emotion/styled";
import ContactsIcon from "@mui/icons-material/Contacts";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import WorkIcon from "@mui/icons-material/Work";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { blueGrey } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled(Paper)`
  display: block;
  @media (min-width: 600px) {
    display: none;
  }
`;
const routeMap: any = {
  "/": 0,
  "/projects": 1,
  "/about": 2,
  "/contact": 3,
};
const BottomNavigationMobile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(routeMap[location.pathname]);

  return (
    <>
      <Container
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setValue(newValue);
          }}
          sx={{ backgroundColor: blueGrey["800"] }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<HomeIcon />}
            onClick={() => navigate("/", { replace: true })}
          />
          <BottomNavigationAction
            label="Projects"
            icon={<WorkIcon />}
            onClick={() => navigate("/projects", { replace: true })}
          />
          <BottomNavigationAction
            label="About"
            icon={<InfoIcon />}
            onClick={() => navigate("/about", { replace: true })}
          />
          <BottomNavigationAction
            label="Contact"
            icon={<ContactsIcon />}
            onClick={() => navigate("/contact", { replace: true })}
          />
        </BottomNavigation>
      </Container>
    </>
  );
};

export default BottomNavigationMobile;
