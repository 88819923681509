import arch from "../../assets/icons/arch.png";
import art2 from "../../assets/icons/art2.png";
import fur from "../../assets/icons/fur.png";
import interior from "../../assets/icons/interior.png";
import socialres from "../../assets/icons/socialres.png";
import unknown from "../../assets/icons/unknown.png";

export const servicesArray = [
  {
    title: "Architecture",
    desc: "Our architectural work spans different sectors, times and locations. Grey Form Studio's team is passionate about the connection between good design and better business performance.",
    path: arch,
  },
  {
    title: "Interior Design",
    desc: "Our opportunity, as designers, is to learn how to handle the complexity, rather than shy away from it, and to realize that the big art of design is to make complicated things simple.",
    path: interior,
  },
  {
    title: "Furniture Design",
    desc: "It is not the form of the building where our imagination stops. We inculcate the urge to imagine beyond the structure and intervene into the art of FURNITURE - TRUE SCULPTURES. The designing of these sculptures adds a massive impact to the space.",
    path: fur,
  },
  {
    title: "Socialize",
    desc: "We are true social beings! One thing we can assure you of is a warm welcome hug and a pleasant smile on your face before you leave. Facebook, Insta, Twitter, Whatsapp are the most frequently used apps in our phones.",
    path: socialres,
  },
  {
    title: "Renovation",
    desc: "The 3 R’s that out team believes in: The art of REUSE with the help of RECYCLING and the power to RENOVATE. We believe in the ability to renovate the existing structure to meet the new requirements.",
    path: art2,
  },
  {
    title: "NotYetKnown",
    desc: "Being a team of young enthusiasts, we are always open to explore our potentials. Along with Mural Designs, Sculpture designs and various other art works, we are positive about entering into new dimensions.",
    path: unknown,
  },
];
