import styled from "@emotion/styled";
import { amber } from "@mui/material/colors";
import Typography from "@mui/material/Typography";

import two from "../../../assets/two.png";
import Section from "../../../components/Section";

const ImageContainer = styled.img`
  width: 70%;
  height: auto;
  padding: 1rem;

  @media (min-width: 600px) {
    width: 40%;
  }
`;
const TextContainer = styled.div`
  text-align: center;
`;

const ThirdSection = () => {
  return (
    <Section backgroundColor={amber["500"]} padding="5">
      <TextContainer>
        <Typography variant="h5" component="h2">
          BUILDINGS ARE STONES, UNTIL WE DESIGN THEM.
        </Typography>
        <ImageContainer loading="lazy" alt="hello" src={two} />
        <Typography variant="h6" component="p" gutterBottom>
          And we’re very proud of it!
        </Typography>
      </TextContainer>
    </Section>
  );
};

export default ThirdSection;
