import styled from "@emotion/styled";
import { teal } from "@mui/material/colors";
import Typography from "@mui/material/Typography";

import three from "../../../assets/three1.png";
import Section from "../../../components/Section";

const ImageContainer = styled.img`
  width: 100%;
  height: auto;
  padding-top: 2rem;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const FourthSection = () => {
  return (
    <Section backgroundColor={teal["600"]} padding="0">
      <TextContainer>
        <Typography
          variant="h4"
          component="h2"
          sx={{ color: "white", paddingTop: "2rem" }}
        >
          We're Architects & interior designers.
        </Typography>
        <Typography variant="h4" component="p" sx={{ color: "white" }}>
          PROUD MINIMALISTS.
        </Typography>
        <Typography variant="h5" component="p" sx={{ color: teal["A100"] }}>
          - Team Grey Form Studio.
        </Typography>
        <ImageContainer loading="lazy" alt="hello" src={three} />
      </TextContainer>
    </Section>
  );
};

export default FourthSection;
