import FifthSection from "./sections/FifthSection";
import FourthSection from "./sections/FourthSection";
import OneSection from "./sections/OneSection";
import SecondSection from "./sections/SecondSection";
import ThirdSection from "./sections/ThirdSection";

const Home = () => {
  return (
    <>
      <OneSection />
      <SecondSection />
      <FourthSection />
      <FifthSection />
      <ThirdSection />
    </>
  );
};

export default Home;
